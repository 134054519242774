.nav-bar {
  display: flex;
  margin: auto;
  padding: 10px 40px;
  column-gap: 60px;
  background: transparent;
  align-items: center;
}

.nav-header .hamburger {
  display: none;
}

.nav-header {
  width: 12%;
}

.nav-header-items {
  width: 100%;
}

.pzLogo {
  width: 100%;
}

.nav-links {
  width: 100%;
  display: flex;
  gap: 60px;
  align-items: center;
  justify-content: space-between !important;
}

.nav-link {
  text-decoration: none;
  color: #23242f;
  cursor: pointer;
  font-weight: 500;
}

.active-link {
  color: #0516c8 !important;
}

.nav-action {
  align-items: center;
  display: flex;
  gap: 30px;
}

.nav-action button {
  border: none;
  color: #ffff;
  background: #0516c8;
}

.v-links {
  display: flex;
  gap: 40px;
}

.ghost-variant {
  background-color: transparent !important;
  color: black !important;
}

.create-btn {
  font-size: 14px !important;
  letter-spacing: 1.5 !important;
}

@media only screen and (max-width: 900px) {
  .nav-header {
    width: 100%;
  }

  .nav-bar {
    font-size: 23px;
    padding: 15px !important;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .nav-header-items img{
    width: auto;
  }

  .nav-header .hamburger {
    display: block;
  }

  .nav-header-items {
    display: flex;
    justify-content: space-between;
  }

  .pzLogo {
    width: 30% !important;
  }

  .hamburger {
    width: 10%;
    cursor: pointer;
  }

  .nav-link{
    font-size: 14px;
  }

  .v-links {
    flex-direction: column;
    row-gap: 25px;
  }

  .create-btn {
   width: 100%;
  }

  .nav-links {
    row-gap: 25px;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    transition: visibility 0s 0.1s, max-height 0.5s ease, opacity 0s ease;
    opacity: 0;
  }

  .nav-links.visible {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    opacity: 1;
    visibility: visible;
    max-height: 600px;
    transition: max-height 0.1s ease, opacity 0.1s ease;
  }

  .nav-action {
    display: flex;
    flex-direction: column;
  }

  .nav-action .login-link {
    color: #ffff;
    font-size: 15px;
    font-weight: 500;
    background: #23242f;
    padding: 16px;
    border-radius: 8px;
    text-align: center;
  }
}
