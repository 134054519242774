.demo-body {
  margin: 10rem auto;
}

.demo-header {
  text-align: center;
  max-width: 40rem;
  margin: auto;
}

.demo-header p {
  color: #585A74;
  margin: 1rem auto;
}

.demo-form {
  max-width: 35rem;
  margin: auto;
  background: #2D2D2D;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
}

.demo-form label {
  color: white;
  font-size: 1.3rem;
}

.demo-form input {
  margin: 0.7rem 0;
  border-radius: 8px;
  padding: 10px;
  width: 70%;
}

.demo-pay-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.demo-pay-buttons button {
  background: #0516c8;
  color: white;
}

@media only screen and (max-width: 900px) {
  .demo-body {
    margin: 1rem auto;
    padding: 1rem;
  }
}