.get-started-container {
  margin: 60px 0;
  background: #f7f8ff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.get-started-container-text {
  padding-left: 150px;
  line-height: 20px;
  width: 530px;
  padding-top: 2rem;
}

.get-started-btn {
  border: none;
  color: white;
  background: #0516c8;
  padding: 0 15px;
  border-radius: 8px;
  display: flex;
  align-items: center !important;
  gap: 15px;
  margin: 50px 0;
}

@media only screen and (max-width: 900px) {
  .get-started-container-text {
    width: 80%;
    margin: auto;
    padding: 40px 0;
    text-align: center;
  }
  .get-started-container-text p{
    margin-top: 30px;
  }
  .get-started-btn {
    justify-content: center;
    align-items: center;
  }
  .get-started-btn img {
    width: 28px;
  }
}
