.footer-section {
  background: #f7f8ff;
  width: 100%
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 50px 80px 0;

}

.footer-img-wrapper {
  width: 15%;
  height: fit-content;
}

.footer-logo {
 width: 100%;
}

.footer-body {
  display: flex;
  justify-content: space-between;
  flex-basis: 50%;
}

.footer-column {
  flex-basis: 50%;
}

.footer-column h5 {
  color: #0516c8;
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 8px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.footer-link {
  text-decoration: none;
  display: block;
}

.mobile-col {
  display: flex;
  gap: 10px;
}

.footer-links p {
  width: 80%;
}

.footer-links p, .footer-links a {
  color: #585A74;
  font-size: 14px;
  font-weight: 500 !important;
  line-height: normal;
  margin: 0;
}

.footer-icons {
  margin-top: 15px;
  display: flex;
  gap: 15px;
}

.bottom-links {
  display: flex;
  justify-content: space-between;
  color: #0516c8;
  margin-top: 40px;
  padding: 30px 80px;
  border-top: 1px solid #F8F1FF;
}

.bottom-links .copyright{
  flex-basis: 35%;
  color: #585A74;
}

.bottom-links .terms {
  flex-basis: 25%;
  display: flex;
  column-gap: 20px;
}

.bottom-links span, .bottom-links a {
  font-size: 14px;
  font-weight: 500;
}

.bottom-links .terms a {
  color: #0516c8;
}

.mobile-col1 {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  .footer-container {
    flex-direction: column;
    padding: 20px 20px 0;
  }

  .footer-body {
    flex-direction: column;
    gap: 30px;
  }

  .footer-img-wrapper {
    width: 35%;
    height: fit-content;
  }

  .footer-container img {
    width: 120px;
    margin-bottom: 30px;
  }

  .footer-column  {
    flex-basis: 100%;
  }

  .footer-links {
    row-gap: 25px;
  }

  .footer-links p, .footer-links a {
    font-size: 12px;
  }

  .bottom-links span, .bottom-links a {
    font-size: 12px;
  }

  .mobile-col, .mobile-col1 {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
  }

  .footer-icons img {
    width: 30px;
  }

  .bottom-links {
    flex-direction: column;
    margin-top: 10px;
    row-gap: 30px;
    padding: 20px 20px;
  }

  .bottom-links .copyright{
    flex-basis: 100%;
  }
  
  .bottom-links .terms {
    flex-basis: 100%;
    gap: 50px;
  }
}
