.api-docs {
	display: flex;
	flex-direction: column;
}

.api-docs p {
	width: 65%;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500 !important;
	color: #8c8ea7;
}

.api-docs h4 {
	margin: 0;
	font-size: 32px;
	font-weight: 700;
	letter-spacing: -0.24px;
	color: #000000;
}

.api-docs a {
	padding: 0 25px !important;
	height: 40px;
	border: none;
	font-size: 14px;
	font-weight: 500;
	border-radius: 4px;
	width: fit-content;
	letter-spacing: 0;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	gap: 10px;
}

.api-button {
	color: #fff;
	background-color: #0516c8;
}

.api-welcome {
	background-color: #f7f8ff;
	padding: 80px 0 0 80px;
	display: flex;
}

.api-welcome-content {
	flex-basis: 50%;
}

.api-welcome p:first-of-type {
	background-color: #dcdefa;
	border-radius: 100px;
	padding: 8px 20px;
	color: #0516c8;
	font-weight: 700 !important;
	width: fit-content;
	letter-spacing: -0.24px;
	font-size: 14px;
}

.api-welcome h4 {
	width: 60%;
	font-size: 36px !important; 
	line-height: 48px;
	color: #0516c8 !important;
}

.api-welcome-content p:nth-of-type(2) {
	width: 50%;
}

.api-support {
	width: 85%;
	margin: auto;
	padding: 80px 0;
}

.api-support-content {
	display: flex;
	gap: 30px;
}

.api-support-content div {
	flex-basis: 50%;
}

.api-support-wrapper {
	width: 100%;
}

.api-support-wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.api-support-content p {
	width: 74% !important;
}

.api-support ul {
	list-style: none;
	list-style-position: outside;
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 0;
	margin: 40px 0;
}

.api-support li {
	color: #23242f;
	font-size: 16px;
	font-weight: 500;
	display: flex;
	align-items: center;
	vertical-align: middle;
	gap: 15px;
}

.get-started {
	background-color: #0516c8;
	padding: 40px 0;
	display: flex;
	flex-direction: column;
	row-gap: 25px;
}

.get-started h4 {
	width: 85%;
	margin: auto;
	color: #fff;
}

.get-started-content {
	width: 85%;
	margin: auto;
	display: flex;
	gap: 25px;
}

.get-started-content div {
	flex-basis: 50%;
	cursor: pointer;
}

.get-started-content img {
	width: 100%;
	object-fit: cover;
}

.api-features {
	padding: 80px 0;
	background-color: #f7f8ff;
	display: flex;
	flex-direction: column;
	row-gap: 50px;
}

.api-features-content {
	display: flex;
	gap: 60px;
	width: 85%;
	margin: auto;
}

.api-features-content:nth-child(even) {
	flex-direction: row-reverse;
}

.api-features-content div {
	flex-basis: 50% !important;
}

.api-features-wrapper img {
	width: 100%;
	object-fit: cover;
}

.api-features-content-cont {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.api-features-content-cont h4 {
	width: 70%;
	line-height: 1.5;
}

.api-features a {
	color: #0516c8;
	background-color: #fff;
}

.api-integration {
	width: 85%;
	margin: 80px auto;
	position: relative;
}

.api-integration-wrapper {
	width: 100%;
}

.api-integration-wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.api-integration-socials {
	position: absolute;
	margin-left: 60px;
	bottom: -12px;
	display: flex;
	gap: 30px;
}

.api-integration-socials a {
	width: fit-content !important;
}

.api-integration-social {
	background-color: #fff;
	padding: 8px 20px;
	border-radius: 50px !important;
	border: 1px solid #0516c8 !important;
	z-index: 2;
	color: #23242f;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
}

.api-integration-socials span {
	gap: 10px;
}

.api-ready {
	width: 85%;
	margin: 80px auto;
	display: flex;
	align-items: center;
	gap: 30px;
}

.api-ready-content {
	flex-basis: 50%;
}

.api-ready-content h4 {
	color: #0516c8;
	width: 50%;
	line-height: 1.5;
}

.api-ready-content p {
	width: 50%;
}

.api-ready-content-buttons {
	display: flex !important;
	gap: 20px;
}

.api-btn-sales {
	background-color: #23242f;
	color: #fff;
}

.img-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	height: 450px;
}

.img-wrapper img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

@media only screen and (max-width: 900px) {
	.api-docs h4 {
		font-size: 20px;
		letter-spacing: -0.24px;
		line-height: 1.2;
	}

	.api-docs p {
		width: 100%;
		font-size: 12px;
		line-height: 1.5;
	}

	.api-docs a,
	.api-btn {
		border-radius: 4px;
		width: 100% !important;
		justify-content: center;
	}

	.chevron-right {
		width: 2%;
	}

	.api-svg {
		width: 10%;
	}

	.api-welcome {
		flex-direction: column;
		padding: 10px 10px 0 10px;
		gap: 40px;
	}

	.api-welcome-content {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		row-gap: 20px;
		justify-content: center;
		align-items: center;
	}

	.api-welcome p:first-of-type {
		padding: 8px 20px;
		font-size: 12px;
		text-align: center;
	}

	.api-welcome h4 {
		width: 100%;
		text-align: center;
	}

	.api-welcome-content p:nth-of-type(2) {
		width: 60%;
		text-align: center;
	}

	.api-support {
		width: 90%;
	}

	.api-support-content div {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}

	.api-support-content {
		flex-direction: column;
	}

	.api-support-content p {
		width: 100% !important;
	}

	.get-started-content {
		flex-direction: column;
	}

	.get-started-content div {
		flex-basis: 100%;
	}

	.api-features-content {
		flex-direction: column;
		width: 90%;
	}

	.api-features-content div {
		flex-basis: 100% !important;
	}

	.api-features-content-cont {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.api-features-content-cont div {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
	}

	.api-features-content-cont h4 {
		width: 100%;
	}

	.api-features-content:nth-child(even) {
		flex-direction: column;
	}

	.api-integration {
		width: 90%;
	}

	.api-integration-socials {
		position: absolute;
		margin-left: 20px;
		bottom: -24px;
		display: flex;
		gap: 15px;
	}

	.api-integration a {
		padding: 2px 8px;
		font-size: 10px;
		gap: 5px;
		border-radius: 100px;
	}

	.api-ready {
		width: 90%;
		flex-direction: column;
	}

	.api-ready-content {
		flex-basis: 100%;
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}

	.api-ready-content h4 {
		width: 100%;
	}

	.api-ready-content-buttons {
		flex-direction: column;
		gap: 10px;
	}

	.api-ready-content-buttons a {
		width: 100% !important;
		align-items: center;
	}
}
