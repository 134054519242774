.testimonials {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.testimonial {
  filter: grayscale(40%);
  transition: filter 0.3s, transform 0.3s;
  color: #8c8ea7;
  width: 35%;
  padding: 20px 10px 10px 10px;
  border-radius: 16px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.testimonial.hovered {
  filter: none;
  transform: scale(1.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  color: #23242f;
  background: #f3f4f6;
  z-index: 1000;
  width: 40%;
  cursor: pointer;
  margin: 10px 0 100px 0;
}
.occupation {
  font-size: 12px;
}
.author-section {
  display: flex;
  align-items: center;
  gap: 10px;
}
.arrow-section {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 60px;
}
.arrow-section img {
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .testimonials {
    height: 500px;
    gap: 0px;
    width: 100%;
    margin: auto;
  }
  .testimonials img {
    width: 25%;
  }
  .testimonial {
    visibility: hidden;
    width: 0;
    padding-bottom: 0;
    border-radius: 0;
  }
  .testimonial.hovered {
    visibility: visible;
    padding: 40px 20px 0 30px;
    width: 100%;
    padding-bottom: 0;
    box-shadow: none;
  }
  .author-section {
    padding-top: 20px;
    text-align: left;
  }
  .arrow-section {
    margin-top: 5px;
  }
  .arrow-section img {
    width: 40px;
  }
}
