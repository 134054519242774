@font-face {
  font-family: "Futura";
  src: url("../assets/fonts/Futura/Futura-Book-font.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("../assets/fonts/Futura/futura-medium-bt.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("../assets/fonts/Futura/Futura-Heavy-font.ttf") format("truetype");
  font-weight: 700;
  font-size: 70px;
  font-style: normal;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../assets/fonts/KumbhSans/KumbhSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../assets/fonts/KumbhSans/KumbhSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../assets/fonts/KumbhSans/KumbhSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../assets/fonts/KumbhSans/KumbhSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Futura";
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, span, a, button  {
  font-family: "Kumbh Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: #ffffff;
  word-wrap: break-word;
}

pre {
  color: #212529;
  display: block;
  font-size: 87.5%;
  margin-bottom: 1rem;
  margin-top: 0;
  overflow: auto;
}

h1 {
  color: #0516c8;
  font-size: 70px;
  font-weight: 700;
  line-height: 76px;
}

h2 {
  color: #0516c8;
  font-size: 48px;
  font-weight: 700;
  line-height: 54px;
}

h4 {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
}

p {
  line-height: 28px;
  font-size: 16px;
  font-weight: 450;
}

button {
  padding: 0 15px !important;
	height: 40px;
	border: none;
	font-size: 14px ;
	font-weight: 500;
	border-radius: 4px;
	width: fit-content;
	letter-spacing: 0;
	display: flex;
	align-items: center;
  justify-content: center;
	gap: 10px;
  cursor: pointer;
}

a {
  font-size: 14px;
  text-decoration: none;
}


@media only screen and (max-width: 900px) {
  * {
    max-width: 100%;
    width: 100%;
    margin: 0;
    overflow-x: hidden;
  }

  h1 {
    font-size: 35px;
    line-height: 1.5;
  }
}
