.page-header {
  display: flex;
  justify-content: space-between;
}

.page-header img {
  width: 305px;
}

.page-header h1 {
  text-align: center;
}

.pricing-header-text {
  text-align: center;
  width: 60%;
  margin: auto;
}

.pricing-categories {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 120px auto;
}

.pricing-category {
  background: #f7f8ff;
  width: 566px;
  border-radius: 16px;
  padding: 70px;
}

.border {
  border-bottom: 1px solid rgba(199, 208, 219, 0.5);
}

.list {
  margin-top: 25px;
}

.list-item {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.list-item span {
  font-weight: 500;
  font-size: 14px;
}

.pricing-faq {
  width: 80%;
  margin: auto;
}

.pricing-faq h2 {
  text-align: center;
}

.faq-redirect {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.faq-redirect span {
  color: #8c8ea7;
  font-size: 18px;
  font-weight: 500;
}

.faq-redirect a {
  display: flex;
  align-items: center;
  color: #0516c8;
}

.faq-question {
  font-weight: 500;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .page-header {
    display: flex;
    flex-direction: column;
  }

  .page-header h1 {
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    padding: 20px 25px;
  }

  .page-header img {
    width: 60px;
  }

  .header-right {
    display: flex;
    justify-content: flex-end;
  }

  .pricing-header-text {
    margin-top: 30px;
  }

  .pricing-categories {
    flex-direction: column;
    text-align: center;
  }

  .pricing-category {
    width: 90%;
    margin: auto;
  }

  .pricing-category h2 {
    padding: 10px;
    font-size: 25px;
  }

  .pricing-category img {
    width: 40px;
  }

  .list {
    text-align: left;
  }

  .pricing-faq {
    width: 90%;
  }

  .faq-redirect {
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    width: 90%;
    margin: auto;
		padding-top: 50px;
  }

  .faq-redirect img {
    width: 20px;
  }
}
