.contact-section-top{
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 40px auto;
}

.contact-section-top-text{
  line-height: 28px;
}

.contact-btn{
  border: none;
  color: #FFFFFF;
  background: #0516C8;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.contact-sales{
  color: #ffff;
  text-decoration: none;
  padding: 0;
}

.message-section{
  background: #DCDEFA;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message-section-body{
  width: 50%;
  margin: auto;
}

.message-form-body{
  width: 80%;
  margin: 50px auto;
}

.message-section-body p{
  color: #484848;
}

.message-section-body h2,
.message-section-body p{
 text-align: center !important;
}

.message-section-body input,
.message-section-body textarea {
  border: none;
  border-radius: 8px;
  padding: 15px;
}

.message-form-row{
  display: flex;
  justify-content: center;
  gap: 30px;
}

.message-form-row input {
  margin: 8px 0 30px 0;
  width: 301px;
}

textarea{
  width: 100%;
  margin: auto;
  margin: 8px 0 30px 0;
}

.form-btn{
  width: 200px;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  cursor: pointer;
}

.contact-options-section{
  background: #F7F8FF;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 60px;
}

.contact-option{
  background: #FFFFFF;
  border-radius: 16px;
  width: 20%;
  padding: 100px 30px 50px 30px;
  align-content: center;
}

.contact-option p{
  color: #000000;
  font-size: 24px;
  font-weight: 700;
}

.contact-option span, .contact-option a{
  color: #8C8EA7;
  text-decoration: none;
}

.contact-icons{
  display: flex;
  gap: 15px;
}

@media only screen and (max-width: 900px) {
  .contact-section-top{
    width: 82%;
    margin: auto;
    text-align: center;
    flex-direction: column;
  }
  .contact-btn{
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }
  .contact-btn img{
    width: 28px;
  }
  .message-form-row{
    flex-direction: column;
    gap: 8px;
  }
  .message-form-row input{
    text-align: center;
  }
  .message-section{
    margin-top: 40px;
  }
  .message-section-body{
    width: 90%;
    margin: auto;
    text-align: center;
  }
  .form-btn{
    width: 100%;
  }
.contact-options-section{
  width: 100%;
  flex-direction: column;
}
.contact-option{
  width: 100%;
  text-align: center;
  padding: 35px;
}
.contact-option img{
  width: 150px;
}
.contact-icons{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.contact-icons img{
  width: 30px;

}


}