.faq-top{
  background: #FFF0F0;
}
.faq-top-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  margin: auto;
  padding: 50px 0;
}
.faq-top-container-text{
  line-height: 28px;
}
.faq-top-container-text input {
  border: none;
  border-radius: 8px;
  padding: 16px;
  width: 370px;
}
.faq-section{
  padding: 50px;
  line-height: 27px;
}
.faq-item{
  margin-top: 10px;
}

.faq-item.open .faq-answer {
  max-height: 1000px;
}
.faq-question{
  background: #F9F9F9;
  padding: 20px;
  border-radius: 8px;
}
.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  color: #8C8EA7;
  padding-top: 18px;
  padding-left: 18px;
  width: 608px;
}

.chevron-icon {
  transition: transform 0.3s ease;
  float: right;
}
.chevron-icon.rotated {
  transform: rotate(180deg);
}


@media only screen and (max-width: 900px) {
  .faq-top-container{
    text-align: center;
    gap: 40px;
  }
  .faq-top-container-text input {
    margin-top: 27px;
  }
  .faq-section{
    text-align: left;
    font-size: 20px;
    line-height: 30px;
  }
}