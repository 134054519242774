.newsletter-container {
  background: linear-gradient(216.74deg, #2C3ADF 14.2%, #0D1359 114.77%);
  color: #FFFFFF;
  width: 100%;
  margin: auto;
  text-align: center;
  padding: 30px;
  border-radius: 16px;
}
.newsletter-container p{
  margin-top: 30px;
}

.sub-input {
  width: 638px;
  margin: 40px auto 20px auto;
  background: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
  border-radius: 20px;
}

.subscribe-btn {
  border: none;
  color: #FFFFFF;
  background: #0516C8;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  gap: 15px;
}

@media only screen and (max-width: 900px) {
  .sub-input input{
    padding-top: 10px;
    text-align: center;
  }
  .subscribe-btn{
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }
  .subscribe-btn img{
    width: 28px;
  }
}