.landing-top {
  display: flex !important;
  justify-content: space-between !important;
  width: 80%;
  margin: 50px auto;
  padding-right: 50px;
}

.landing-top2 .landing-heading .lh-text {
  width: 488px;
  margin: auto;
}

.landing-heading {
  padding-top: 35px;
}

.landing-heading h1 {
  width: 586px;
}

.landing-heading p {
  color: #8c8ea7;
  line-height: 28px;
  margin-top: 1.3rem;
}

.landing-action-btns {
  display: flex;
  flex-wrap: wrap;
}

.contact-sales {
  color: #ffff;
  text-decoration: none;
}

.landing-heading button {
  border: none;
  padding: 16px;
  border-radius: 8px;
  margin-right: 10px;
  color: #ffff;
  margin-top: 40px;
}

.how-it-works-section {
  width: 80%;
  margin: 120px auto !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.how-it-works-grid {
  display: flex;
  width: 100%;
  margin: auto !important;
  justify-content: center;
  margin-top: 60px;
  gap: 50px;
}
.step-container {
  background-color: #f7f8ff;
  width: 355px !important;
  padding: 40px 30px 30px 30px;
  border-top-left-radius: 64px;
  border-top-right-radius: 64px;
}
.step-container p {
  font-size: 14px;
  color: #8c8ea7;
  line-height: 24px;
}

.payment-methods-section {
  width: 1052px;
  margin: auto !important;
}
.payment-method {
  border-radius: 16px;
  background: #f7f8ff;
  width: 1152px;
  margin: 80px auto !important;
  display: flex;
  padding: 50px;
  padding-bottom: 0;
}

.ussd {
  padding: 70px;
  padding-bottom: 0;
}
.pm-text {
  margin-left: 40px;
}
.pm-text .ussd-text {
  margin-right: 80px;
}
.pm-text p {
  line-height: 26px;
}

.offerings-section {
  background: #f3f4f6;
  margin-top: 110px;
  padding: 70px 0;
}
.flex-grid-container {
  width: 80%;
  margin: auto;
}
.flex-grid-row {
  display: flex;
  width: 1153px;
  margin: auto !important;
  margin-top: 20px !important;
  justify-content: center;
  gap: 25px;
  margin-bottom: 40px;
}
.flexbox-t {
  background-color: #ffff;
  border-radius: 8px;
  width: 371px;
  line-height: 26px;
}
.flexbox-t h4 {
  color: #0516c8;
}
.flexbox-t p {
  color: #8c8ea7;
}

.testimonial-section {
  padding: 80px;
}
.testimonial-header {
  text-align: center;
}
.testimonials-container {
  margin-top: 90px;
}
.testimonial-header p {
  color: #8c8ea7;
}

.trusted-by {
  margin: 50px 0;
  background: #f3f4f6;
  padding: 10px;
  padding-bottom: 40px;
  text-align: center;
}
.trusted-by-imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.create-account {
  display: flex;
  background: #f7f8ff;
  justify-content: center;
  gap: 70px;
  width: 100%;
  padding: 40px;
}
.create-account-text {
  padding-top: 70px;
  width: 450px;
}
.create-account-text p {
  color: #8c8ea7;
}

.flex-btns {
  display: flex;
}

.create-account button {
  border: none;
  padding: 16px;
  border-radius: 8px;
  margin-right: 10px;
  color: #ffff;
  margin-top: 50px;
}

@media only screen and (max-width: 900px) {
  .landing-top {
    flex-direction: column;
    width: 82%;
    margin: auto;
    text-align: center;
    padding-right: 0;
  }
  .landing-heading p {
    padding: 20px 0;
  }
  .landing-heading h2 {
    font-weight: 900 !important;
  }

  .landing-action-btns button {
    margin-top: 30px;
  }
  .landing-top img {
    margin-top: 40px;
  }
  .pt-2-mobile {
    flex-wrap: wrap-reverse;
  }
  .how-it-works-grid {
    flex-direction: column;
    margin-left: 0;
  }
  .step-container {
    width: 90%;
  }
  .step-container img {
    width: 20%;
    margin-bottom: 30px;
  }
  .step-container p {
    line-height: 30px;
    font-size: 18px;
  }
  .payment-methods-section {
    width: 90%;
  }
  .payment-method {
    width: 100%;
    text-align: center;
    padding: 30px;
    padding-bottom: 0;
  }
  .cards {
    flex-direction: column;
  }
  .ussd {
    padding: 30px 30px 0 30px;
    flex-direction: column;
  }
  .pm-text {
    margin: 0;
  }
  .transfer {
    flex-wrap: wrap-reverse;
  }
  .pm-text p {
    padding: 40px 0;
  }
  .flex-grid-row{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .offer-img{
    width: 371px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flexbox-t {
    text-align: center;
    height: 378px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flexbox-t img {
    width: 40px;
  }
  .testimonial-section {
    padding: 0;
    width: 80%;
    margin: 50px auto;
  }
  .testimonial-header p {
    padding: 20px 0;
  }
  .testimonials-container {
    margin-top: 20px;
  }
  .trusted-by-imgs {
    width: 180px;
    margin: auto;
    margin-top: 30px;
  }
  .create-account {
    text-align: center;
    padding: 0;
    flex-direction: column;
  }
}

@media only screen and (max-width: 700px) {
}
